<template>
  <section ref="popError" id="popError" class="pop notice error" @click="popClose">
    <div class="popBody">
      <div>
        <div class="icon">
          <div>
            <font-awesome-icon icon="fa-solid fa-exclamation" size="3x"></font-awesome-icon>
          </div>
        </div>
        <span class="title"> {{ $t('System notice') }}</span>
      </div>
      <div v-html="message">
        <!-- <p style="color: #828282; text-align: center">
          接獲銀行端通知，目前信用卡、Apple Pay、以Google
          Pay服務可能會有刷卡失敗的狀況，請大家選擇其他支付方式，造成不便，敬請見諒
        </p>
        <p style="color: #828282; text-align: center">
          如有疑慮，可來電<a href="tel:02-77515336" style="color: red"
            >02-77515336</a
          >，或透過我們的<a style="color: red">LINE客服</a>詢問。
        </p> -->
      </div>
      <button @click="popClose">
        <span> {{ $t('Confirm') }}</span>
      </button>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import { mapState } from 'vuex';

export default {
  name: 'PopupNotice',
  props: {
    title: String,
    message: String,
    closeCallback: {
      type: Function,
    },
  },
  watch: {
  },
  data() {
    return {};
  },
  mounted() {
    // this.$el.addEventListener('click', this.onClick)
    // this.popup();
  },
  methods: {
    popClose() {
      var popError = this.$refs['popError']; // document.getElementById('popError');
      // popError.style.display = 'none';
      // 下滑闗閉
      popError.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        popError.style.display = 'none';
        this.$emit('close');
        popError.children[0].style.animationName = 'popup';
        
      }, 300)
      if (this.closeCallback && typeof this.closeCallback === 'function') {
        console.log('call close callback')
        this.closeCallback();
      }
      document.body.style.overflow = 'scroll';

    },
  },
};
</script>
