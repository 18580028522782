<template>
  <!-- Popup (Refund) -->
  <section ref="popRefund" id="popRefund" class="pop notice" @click="popClose">
    <div class="popBody">
      <div>
        <span class="title">退款流程及方式</span>
      </div>
      <ol>
        <li>已經完成接單的訂單，無法線上退款。</li>
        <li>
          若需退單退款，請直接聯絡門市人員，門市人員確認訂單狀況，會協助進行退單退款。
        </li>
      </ol>
      <button @click="popClose">
        <span>確認</span>
      </button>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'PopupInfo',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
    }),
  },
  methods: {
    popClose() {
      // var popRefund = document.getElementById('popRefund');
      // popRefund.style.display = 'none';
      // document.body.style.overflow = 'scroll';
      let target = this.$refs['popRefund'];
      target.children[0].style.animationName = 'popdown'
      setTimeout(() => {
        target.style.display = 'none';
        this.$emit('close');
        target.children[0].style.animationName = 'popup';
      }, 300)
      document.body.style.overflow = 'scroll';
    },
  },
};
</script>
