<template>
  <section ref="popOrder" id="popOrder" class="pop notice order" @click="popClose">
    <div class="popBody">
      <div>
        <span class="title">線上點餐須知</span>
      </div>
      <div class="content">
        <span class="header">一、點餐說明</span>
        <span class="subheader">一般點餐訂購流程</span>
        <span>選擇用方式 -> 選購餐點 -> 前往結帳 -> 填寫資料 -> 選擇付款方式 -> 一鍵下訂 -> 選擇付款方式 -> 購物需知確認 -> 訂購完成</span>
        <span class="subheader">訂單查詢</span>
        <span>當您完成訂購程序後，您可以利用頁面下方選單的「訂單」訂單查詢功能，查詢目前的訂單狀態。</span>
        <span class="header">二、付款方式</span>
        <span>目前提供多種支付方式。</span>
        <span class="subheader">信用卡線上刷卡</span>
        <span>您可使用信用卡的線上刷卡服務，當您在結帳頁面進行信用卡相關資料填寫時，請務必詳實填寫正確資料，以利銀行做信用卡資料的審核。</span>
        <span>※【提醒您】使用信用卡付費時，依據信用卡定型化契約第六條（契約雙方之基本義務）之規定：銀行僅授權持卡人本人在信用卡有效期限內分別使用，不得讓與、轉借、提供擔保或以其他方式將信用卡之占有轉讓予第三人或交其使用。</span>
      </div>
      <button @click="popClose">
        <span>確認</span>
      </button>
    </div>
  </section>
</template>

<script>
  // @ is an alias to /src
  import { mapState } from 'vuex';

  export default {
    name: 'PopupOrder',
    data() {
      return {};
    },
    computed: {
      ...mapState({
        isLoggedIn: (state) => state.isLoggedIn,
      }),
    },
    methods: {
      popClose() {
        // var popOrder = document.getElementById('popOrder');
        // popOrder.style.display = "none";
        // document.body.style.overflow = "scroll";
        let target = this.$refs['popOrder'];
        target.children[0].style.animationName = 'popdown'
        setTimeout(() => {
          target.style.display = 'none';
          this.$emit('close');
          target.children[0].style.animationName = 'popup';
        }, 300)
        document.body.style.overflow = 'scroll';
      },
    },
  };
</script>